* {
	box-sizing: border-box;
}

html::-webkit-scrollbar {
	display: none;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: "Bebas Neue", cursive;
}

a:visited,
a:link,
a:active,
a:hover {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

h1 {
	font-family: "Bebas Neue", cursive;
}
p {
	font-family: Helvetica;
}

button {
	font-size: 14px;
}
button:hover {
	cursor: pointer;
}

.light {
	body {
		background-color: whitesmoke;
		position: fixed;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	.home-page {
		height: 100%;
		width: 100vw;
		position: absolute;
		background-color: transparent;
	}
	.button {
		font-family: "Bebas Neue", cursive;
		text-transform: uppercase;
		height: 32px;
		width: 32px;
		border: 3px solid;
		padding: 0.25em 0.5em;
		box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
			4px 4px 0px 0px, 5px 5px 0px 0px;
		position: relative;
		touch-action: manipulation;
	}
	.button:active {
		box-shadow: 0px 0px 0px 0px;
		height: 32px;
		width: 32px;
	}
	.landing {
		z-index: 1;
		position: absolute;
		top: 50%;
		width: 50%;
		left: 25%;
		right: 25%;
		text-align: center;
		font-size: 14px;
		border-top: 2px solid black;
		h1 {
			font-family: "Bebas Neue", cursive;
			color: black;
		}
		.button {
			align-items: center;
			left: 0rem;
			width: fit-content;
			text-align: center;
		}
		.button:active {
			box-shadow: 0px 0px 0px 0px;
			top: 0rem;
			width: fit-content;
		}
	}
	.canvas {
		background-color: whitesmoke;
		height: 100%;
		line-height: 0%;
		font-size: 0%;
	}
	.linkedin-icon {
		height: 30px;
		width: 30px;
	}

	.project-page {
		height: 100vh;
		background-color: whitesmoke;
		.intro {
			background-color: whitesmoke;
			padding: 1rem;
			height: auto;
			width: 100%;
		}
		.project-cards {
			background-color: whitesmoke;
			height: auto;
			width: auto;
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
			grid-gap: 1em;
			padding: 1em;
			.project-card-map {
				// padding: 1rem;
				border: 3px groove black;
				box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
					4px 4px 0px 0px, 5px 5px 0px 0px;
			}
			h1 {
				text-transform: uppercase;
				text-decoration: underline;
				font-size: 22px;
				word-wrap: normal;
				padding: 0rem;
				text-align: center;
			}
			p {
				text-align: justify;
			}
			.buttons {
				display: flex;
				align-items: center;
				justify-content: center;
				height: auto;
				.button {
					margin: 1rem;
					margin-bottom: 1rem;
					font-size: small;
					margin-bottom: 3rem;
				}
				#github-repo {
					width: auto;
					height: auto;
				}
				#hosted-link {
					width: auto;
					height: auto;
				}
			}
			p {
				font-size: 15px;
			}
			.language-icon-map {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(45px, 45px));
				grid-gap: 0.75em;
				margin: 1rem;
			}
			.language-icon {
				height: 100%;
				width: 100%;
			}
		}
	}
	.about-and-contact {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: 1fr 1fr;
		background-color: whitesmoke;
		.liam-headshot {
			padding: 1rem;
			width: 275px;
			border-radius: 50%;
			justify-content: center;
			align-content: center;
			margin: auto;
		}
		.about {
			background-color: whitesmoke;
			padding: 1rem;
			height: auto;
			width: 100%;
			p {
				font-size: 14px;
				text-align: justify;
			}
			.button {
				font-family: "Bebas Neue", cursive;
				text-transform: uppercase;
				color: #000;
				border: 3px solid;
				padding: 0.25em 0.5em;
				box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
					4px 4px 0px 0px, 5px 5px 0px 0px;
				position: relative;
				margin-bottom: 1rem;
				left: 45%;
				right: 45%;
				width: fit-content;
				touch-action: manipulation;
			}
			.button:active {
				box-shadow: 0px 0px 0px 0px;
				margin-bottom: 1rem;
				left: 45%;
				right: 45%;
				width: fit-content;
			}
		}
		.contact {
			position: relative;
			padding: 1rem;
			height: auto;
			width: 100%;
			background-color: whitesmoke;
			p {
				font-size: 14px;
			}
		}
	}
	.navbar {
		background-color: whitesmoke;
		padding: 1rem;
		z-index: 5;
		.button {
			position: fixed;
			width: 2rem;
			top: 1rem;
			z-index: 10;
		}
		#theme-button {
			font-family: "Bebas Neue", cursive;
			text-transform: uppercase;
			font-weight: bold;
			color: #000;
			border: 3px solid;
			padding: 0.25em 0.5em;
			box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
				4px 4px 0px 0px, 5px 5px 0px 0px;
			position: fixed;
			top: 1rem;
			left: 4rem;
			width: 2rem;
			z-index: 10;
			touch-action: manipulation;
			height: 32px;
			width: 32px;
		}
		#theme-button:active {
			box-shadow: 0px 0px 0px 0px;
			left: 4rem;
			z-index: 10;
			height: 32px;
			width: 32px;
		}
		.hamburger-menu-content {
			position: fixed;
			top: 4rem;
			z-index: 10;
			display: flex;
			flex-direction: column;
			justify-content: left;
			align-items: flex-start;
			background-color: whitesmoke;
		}
		.hamburger-menu-content:hover {
			background-color: whitesmoke;
		}
		#hide,
		.hide {
			display: none;
		}
		#show,
		.show {
			display: block;
		}
	}
	.header-icons {
		z-index: 2;
		position: fixed;
		right: 1rem;
		top: 1rem;
		.github-icon-header {
			height: 30px;
			width: 30px;
			position: absolute;
			right: 1em;
		}
		.linkedin-icon-header {
			height: 30px;
			width: 30px;
			position: absolute;
			right: 4rem;
		}
		.CV-icon-header {
			height: 30px;
			width: 30px;
			position: absolute;
			right: 7rem;
		}
	}
	.button-change-colors {
		font-family: "Bebas Neue", cursive;
		text-transform: uppercase;
		color: #000;
		border: 3px solid;
		padding: 0.25em 0.5em;
		box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
			4px 4px 0px 0px, 5px 5px 0px 0px;
		position: fixed;
		bottom: 1rem;
		left: 1rem;
		width: fit-content;
		z-index: 10;
		touch-action: manipulation;
	}
	.button-change-colors:active {
		box-shadow: 0px 0px 0px 0px;
		z-index: 10;
		bottom: 1rem;
		left: 1rem;
		width: fit-content;
		z-index: 10;
	}
	.error {
		padding: 1em;
		height: 100vh;
		.button {
			width: fit-content;
		}
	}
}

.dark {
	body {
		background-color: rgb(37, 37, 37);
		border: rgb(37, 37, 37) 2px solid;
		position: fixed;
	}
	.home-page {
		height: 100%;
		width: 100vw;
		position: absolute;
		background-color: rgb(37, 37, 37);
	}
	.button {
		font-family: "Bebas Neue", cursive;
		text-transform: uppercase;
		color: #000;
		height: 32px;
		width: 32px;
		border: 3px solid;
		padding: 0.25em 0.5em;
		box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
			4px 4px 0px 0px, 5px 5px 0px 0px;
		position: relative;
		touch-action: manipulation;
		text-align: center;
	}
	.button:active {
		box-shadow: 0px 0px 0px 0px;
		height: 32px;
		width: 32px;
	}
	.landing {
		z-index: 1;
		position: absolute;
		top: 50%;
		width: 50%;
		left: 25%;
		right: 25%;
		text-align: center;
		font-size: 14px;
		border-top: 2px solid rgb(255, 255, 255);
		box-shadow: -1px #000;
		h1 {
			font-family: "Bebas Neue", cursive;
			color: white;
			text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
				1px 1px 0 #000;
		}
		.button {
			align-items: center;
			left: 0rem;
			width: fit-content;
			text-align: center;
		}
		.button:active {
			box-shadow: 0px 0px 0px 0px;
			top: 0rem;
			width: fit-content;
		}
	}
	.canvas {
		height: 100%;
	}
	.linkedin-icon {
		height: 30px;
		width: 30px;
	}
	.project-page {
		height: 100vh;
		background-color: rgb(37, 37, 37);
		.intro {
			color: whitesmoke;
			background-color: rgb(37, 37, 37);
			padding: 1rem;
			height: auto;
			width: 100%;
		}
		.project-cards {
			background-color: rgb(37, 37, 37);
			height: max-content;
			width: auto;
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
			grid-gap: 1em;
			padding: 1em;
			.project-card-map {
				padding: 1rem;
				border: 3px groove black;
				box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
					4px 4px 0px 0px, 5px 5px 0px 0px;
			}
			h1 {
				color: whitesmoke;
				text-transform: uppercase;
				text-decoration: underline;
				font-size: 22px;
				word-wrap: normal;
				padding: 0rem;
				text-align: center;
			}
			p {
				color: whitesmoke;
				text-align: justify;
			}
			.buttons {
				display: flex;
				align-items: center;
				justify-content: center;
				height: auto;
				color: whitesmoke;
				.button {
					margin: 1rem;
					margin-bottom: 1rem;
					font-size: small;
					margin-bottom: 3rem;
					background-color: white;
				}
				#github-repo {
					width: auto;
					height: auto;
				}
				#hosted-link {
					width: auto;
					height: auto;
				}
			}
			p {
				font-size: 15px;
			}
			.language-icon-map {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(45px, 45px));
				grid-gap: 0.75em;
				margin: 1rem;
			}
			.language-icon {
				height: 100%;
				width: 100%;
			}
		}
	}
	.about-and-contact {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: 1fr 1fr 1fr;
		background-color: rgb(37, 37, 37);
		color: whitesmoke;
		.liam-headshot {
			padding: 1rem;
			width: 275px;
			border-radius: 50%;
			justify-content: center;
			align-content: center;
			margin: auto;
		}
		.about {
			background-color: rgb(37, 37, 37);
			padding: 1rem;
			width: 100%;
			p {
				font-size: 14px;
				text-align: justify;
			}
			.button {
				font-family: "Bebas Neue", cursive;
				text-transform: uppercase;
				color: #000;
				border: 3px solid;
				padding: 0.25em 0.5em;
				box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
					4px 4px 0px 0px, 5px 5px 0px 0px;
				position: relative;
				left: 45%;
				right: 45%;
				margin-bottom: 1rem;
				width: fit-content;
				touch-action: manipulation;
				background-color: white;
			}
			.button:active {
				box-shadow: 0px 0px 0px 0px;
				left: 45%;
				right: 45%;
				margin-bottom: 1rem;
				width: fit-content;
				background-color: white;
			}
		}
		.contact {
			position: relative;
			padding: 1rem;
			width: 100%;
			background-color: rgb(37, 37, 37);
			color: whitesmoke;
			p {
				font-size: 14px;
			}
		}
	}
	.header {
		background-color: rgb(37, 37, 37);
		.navbar {
			padding: 1rem;
			z-index: 5;
			background-color: rgb(37, 37, 37);
			.button {
				position: fixed;
				width: 2rem;
				top: 1rem;
				z-index: 100;
			}
			#theme-button {
				font-family: "Bebas Neue", cursive;
				text-transform: uppercase;
				font-weight: bold;
				color: #000;
				border: 3px solid;
				padding: 0.25em 0.5em;
				box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
					4px 4px 0px 0px, 5px 5px 0px 0px;
				position: fixed;
				top: 1rem;
				left: 4rem;
				z-index: 10;
				touch-action: manipulation;
				height: 32px;
				width: 32px;
			}
			#theme-button:active {
				box-shadow: 0px 0px 0px 0px;
				left: 4rem;
				z-index: 10;
				height: 32px;
				width: 32px;
			}
			.hamburger-menu-content {
				position: fixed;
				top: 4rem;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: left;
				align-items: flex-start;
				background-color: rgb(37, 37, 37);
				.dropdown-link {
					color: whitesmoke;
				}
			}
			.hamburger-menu-content:hover {
				color: whitesmoke;
			}
			#hide,
			.hide {
				display: none;
			}
			#show,
			.show {
				display: block;
			}
		}
		.header-icons {
			z-index: 2;
			position: fixed;
			right: 1rem;
			top: 1rem;
			.github-icon-header {
				height: 30px;
				width: 30px;
				position: absolute;
				right: 1em;
			}
			.linkedin-icon-header {
				height: 30px;
				width: 30px;
				position: absolute;
				right: 4rem;
			}
			.CV-icon-header {
				height: 30px;
				width: 30px;
				position: absolute;
				right: 7rem;
			}
		}
	}
	.button-change-colors {
		font-family: "Bebas Neue", cursive;
		text-transform: uppercase;
		color: #000;
		border: 3px solid;
		padding: 0.25em 0.5em;
		box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px,
			4px 4px 0px 0px, 5px 5px 0px 0px;
		position: fixed;
		bottom: 1rem;
		left: 1rem;
		width: fit-content;
		z-index: 10;
		touch-action: manipulation;
	}
	.button-change-colors:active {
		box-shadow: 0px 0px 0px 0px;
		z-index: 10;
		bottom: 1rem;
		left: 1rem;
		width: fit-content;
		z-index: 10;
	}
	.error {
		padding: 1em;
		height: 100vh;
		background-color: rgb(37, 37, 37);
		color: whitesmoke;
		.button {
			width: fit-content;
		}
	}
}

@media screen and (max-width: 700px) {
	.light {
		.about-and-contact {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr auto;
			.about {
				.button {
					margin-left: auto;
					margin-right: auto;
					margin-top: 10px;
					text-align: center;
				}
				.button:active {
					margin-left: auto;
					margin-right: auto;
					margin-top: 10px;
					text-align: center;
				}
			}
			.liam-headshot {
				width: 200px;
			}
		}
	}
	.dark {
		.about-and-contact {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr auto;
			.about {
				.button {
					margin-left: auto;
					margin-right: auto;
					margin-top: 10px;
					text-align: center;
				}
				.button:active {
					margin-left: auto;
					margin-right: auto;
					margin-top: 10px;
					text-align: center;
				}
			}
			.liam-headshot {
				width: 200px;
			}
		}
	}
}
